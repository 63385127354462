import _ from 'lodash';

import documentApi, { TableDataQuery } from 'api/document/documentApi';
import userFilterApi from 'api/user/userFilterApi';
import { FwToast, ModuleStore } from 'components/base';
import { BUTTON_TYPE, CONTENT_TYPE } from 'core/utils/constant';
// import printAll from 'core/utils/print';
import { sortByStepRowColumnKey } from 'core/utils/sort';
import utils from 'core/utils/utils';

import { getExtendDocs } from '../function/document';

const { download, filter, link, popup, print, reset, save, submit } =
  BUTTON_TYPE;

const getEffect = (effectType) => {
  let effect;

  switch (effectType) {
    case download:
      effect = downloadEffect;
      break;
    case filter:
      effect = filterEffect;
      break;
    case link:
      effect = linkEffect;
      break;
    case popup:
      // todo
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      effect = () => async () => {};
      break;
    case print:
      effect = printEffect;
      break;
    case reset:
      effect = resetEffect;
      break;
    case save:
    case submit:
      effect = submitEffect;
      break;
    default:
      break;
  }

  return effect;
};

const downloadEffect =
  ({
    templateName,
    fileType,
    downloadFileName,
    limitPageByCollectionRow,
    moduleStore,
  }: {
    templateName: string;
    fileType: string;
    downloadFileName: string[];
    limitPageByCollectionRow: string;
    moduleStore: ModuleStore;
  }) =>
  async (documentRef) => {
    if (templateName && fileType) {
      const tableDatas: TableDataQuery[] = [];

      // build tableData query inside document download if has
      if (moduleStore?.tableDatas) {
        // get all table page contents from template
        const tables = utils.getContentsFromTemplatesByType(
          [documentRef.current.template],
          CONTENT_TYPE.table
        );

        // to download document with tableData, the template must has only 1 step which contains all the table
        // sort by row column then keep tableKey sorted
        const sortedTableKeys: string[] = _.uniq(
          _.map(sortByStepRowColumnKey(tables), (t) => t.tableKey)
        );

        _.forEach(sortedTableKeys, (tk) => {
          const data = moduleStore.tableDatas[tk];

          // module store contains this tableKey then push data
          if (data) {
            tableDatas.push({
              tableKey: tk,
              ids: data.rows.map((r) => r.rowID),
            });
          }
        });
      }

      const res = await documentApi.downloadAs(
        documentRef.current.documentID,
        templateName,
        fileType,
        downloadFileName,
        tableDatas,
        limitPageByCollectionRow
      );

      if (res && res.data?.fileUrl) {
        // open to save file
        utils.openInNewTab(res.data.fileUrl);
      }
    }
  };

const filterEffect =
  ({ redirectTo, additionalData, userFilters, setUserFilters }) =>
  async (documentRef, docDataRef) => {
    const dataHasStringValue = {};

    // keep only data which has string value
    _.forOwn(docDataRef.current, (value, key) => {
      if (_.isString(value)) {
        dataHasStringValue[key] = value;
      }
    });

    const editableFilters = utils.mapDataToFilters(dataHasStringValue);

    const res = await userFilterApi.putMany(editableFilters);

    if (res.status === 204) {
      // success then update state
      setUserFilters({
        ...userFilters,
        editableFilters,
      });
    }

    const isAbsoluteUrl = _.startsWith(additionalData?.url, 'http');

    return isAbsoluteUrl
      ? await linkEffect({ additionalData })()
      : {
          location: `${redirectTo || ''}${additionalData?.url || ''}`,
        };
  };

const linkEffect =
  ({ additionalData }) =>
  async () => {
    // delay redirect to wait for end of execution
    setTimeout(() => (window.location.href = additionalData?.url), 1000);

    // prevent state update
    return {};
  };

const printEffect =
  (/*{ template }*/) => async (/*documentRef, docDataRef*/) => {
    // const data = utils.getFullData(documentRef.current, docDataRef.current);
    // printAll([data], template);
  };

const resetEffect = () => async (documentRef, docDataRef, dispatch) => {
  // re-initialize value then dispatch to update state
  _.forOwn(docDataRef.current, (_, key) => {
    docDataRef.current[key] = '';
  });

  dispatch({ docData: _.cloneDeep(docDataRef.current) });
};

const submitEffect =
  ({ t, additionalData, redirectTo, toStatus }) =>
  async (documentRef, docDataRef, _dispatch, linkTemplateID) => {
    let redirect;

    const dataSubmit = _.cloneDeep(docDataRef.current);
    const extendDocs = getExtendDocs(
      dataSubmit,
      documentRef.current,
      linkTemplateID
    );

    // update document
    const formSubmit = {
      ...documentRef.current,
      data: JSON.stringify(dataSubmit),
      status: toStatus,
      extendDocuments: extendDocs,
    };
    formSubmit.template = {
      templateId: formSubmit.template.templateId,
    };

    const response = documentRef.current.active
      ? await documentApi.put(formSubmit, additionalData?.preventNotify)
      : await documentApi.post(null, formSubmit, additionalData?.preventNotify);

    if (response?.status === 204 || response?.status === 201) {
      if (!redirectTo) {
        redirect = { external: true };
        window.location = docDataRef.current['ReturnURL'];
      } else {
        redirect = { location: redirectTo };

        if (response.status === 204) {
          FwToast.success(
            `${t('Document')} ${documentRef.current.number} ${t(
              'was successfully updated'
            )}`
          );
        } else if (response.status === 201) {
          FwToast.success(
            `${t('Document created with ID')} ${(response.data as any).number}`
          );
        }
      }
    } else {
      FwToast.error(t('Action could not be performed'));
    }

    return redirect;
  };

const applyButtonEffect =
  (buttonEffect) =>
  async (documentRef, docDataRef, dispatch, linkTemplateID) => {
    const result = buttonEffect
      ? await buttonEffect(documentRef, docDataRef, dispatch, linkTemplateID)
      : undefined;
    return result;
  };

export {
  getEffect,
  downloadEffect,
  linkEffect,
  printEffect,
  resetEffect,
  submitEffect,
};

export default applyButtonEffect;
