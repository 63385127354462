import React, { FC, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, RouteChildrenProps } from 'react-router-dom';

import {
  FwAreaProvider,
  FwAuthProvider,
  FwSpinner,
  FwSettingsProvider,
} from 'components/base';
import { i18n, setI18nDefaultLocale } from 'config/i18n';
import * as serviceWorker from 'config/serviceWorker';

import App from './App';

setI18nDefaultLocale(i18n);

// setup logging
if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', 'fasterweb:*');
}

const FasterWebApp: FC<RouteChildrenProps> = ({ history, location }) => {
  return (
    <FwAreaProvider location={location}>
      <FwAuthProvider history={history} location={location}>
        <App />
      </FwAuthProvider>
    </FwAreaProvider>
  );
};

const container = document.getElementById('renderBody');
const root = createRoot(container);

root.render(
  // check if the data/component has been successfully fetched, and if not, show a spinner
  <BrowserRouter>
    <FwSettingsProvider>
      <Suspense fallback={<FwSpinner />}>
        <Route component={FasterWebApp} />
      </Suspense>
    </FwSettingsProvider>
  </BrowserRouter>
);

// if you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls
// learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
