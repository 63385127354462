import { bool, elementType, func, InferProps, node, string } from 'prop-types';

import { ctor } from '../helper';
import { stylePT } from './common';

const fwSegmentPT = {
  ...stylePT,
  as: elementType,
  children: node,
  active: bool,
  basic: bool,
  center: bool,
  compact: bool,
  frosted: bool,
  name: string,
  noOverflow: bool,
  padded: bool,
  small: bool,
  sticky: bool,
  tile: bool,
  onClick: func,
};

type FwSegmentPT = InferProps<typeof fwSegmentPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwSegmentProps extends FwSegmentPT {}
class FwSegmentProps implements FwSegmentPT {
  constructor(props?: FwSegmentPT) {
    ctor(this, props);
  }
}

export { fwSegmentPT, FwSegmentProps };
