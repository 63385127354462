import _ from 'lodash';
import React, { FC, useState, useRef, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import documentApi from 'api/document/documentApi';
import {
  FwDrawer,
  FwToast,
  FwModal,
  PageContentStore,
  PageStore,
  ModuleStore,
  useFwModuleStore,
  useFwPageStore,
  useFwPageContentStore,
  useFwTemplates,
} from 'components/base';
import DownloadButtonContainer from 'components/button/downloadButtonContainer';
import { DocState } from 'components/doc/function/document';
import { updateState } from 'components/doc/helpers';
import { getEntityDoc } from 'components/entity-info/helpers';
import {
  api,
  executeScript,
} from 'components/form/components/template/helpers/executeScript';
import {
  BatchProcess,
  Document,
  ExecutionFilter,
  Filter,
  FwModalProps,
  Input,
  Option,
} from 'core/model';
import { FwProcessProps, fwProcessPT } from 'core/model/props/FwProcess.props';
import { BUTTON_TYPE, SYSTEM_FIELDS } from 'core/utils/constant';
import { MODAL_TYPES, PROCESS_TYPE } from 'core/utils/constants';
import {
  getIdsByStepIndex,
  getCloneStoreUpdatedProcessData,
  getProcessDataByPrevStep,
  getProcessDataByStepIndex,
} from 'core/utils/processButton/utilsProcess';
import { sortByStepRowColumnKey } from 'core/utils/sort';
import utils from 'core/utils/utils';
import {
  isValidDocument,
  defaultModal,
  getModalOptions,
  buildModalFromProcessInfos,
} from 'core/utils/utilsModal';

const FwProcess: FC<FwProcessProps> = ({
  children,
  docId,
  docNumber,
  docData,
  processID,
  processSteps,
  objectData,
  processType,
  placement,
  // functions
  handleDocValidationScroll,
  onInputChange,
}) => {
  const { t } = useTranslation();
  const { templates } = useFwTemplates();
  const { moduleStore } = useFwModuleStore() || {};
  const { pageStore } = useFwPageStore() || {};
  const { pageContentStore, setPageContentStore } = useFwPageContentStore();

  // todo wip #672 refactor type
  const [modal, setModal] = useState<{
    isOpen?: boolean;
    modalType?: `${MODAL_TYPES}`;
    options?: FwModalProps;
  }>(defaultModal);
  const [invalidInputKey, setInvalidInputKey] = useState<string>();
  const [stepIndex, setStepIndex] = useState<number>();
  const [prevProcessID, setPrevProcessID] = useState<string>();

  const pageContentStoreRef = useRef<PageContentStore>(pageContentStore);
  const pageStoreRef = useRef<PageStore>(pageStore);
  const moduleStoreRef = useRef<ModuleStore>(moduleStore);

  const isDrawer = processType === PROCESS_TYPE.drawer;

  useEffect(() => {
    moduleStoreRef.current = moduleStore;
  }, [moduleStore]);

  useEffect(() => {
    pageStoreRef.current = pageStore;
  }, [pageStore]);

  useEffect(() => {
    pageContentStoreRef.current = pageContentStore;
  }, [pageContentStore]);

  useEffect(() => {
    if (processID && processSteps) {
      setStepIndex(0);
    }
  }, [processID, processSteps]);

  useEffect(() => {
    if (stepIndex >= 0) {
      setPrevProcessID(processID);

      // get step by index
      const stepProcess = processSteps[stepIndex];

      if (stepProcess) {
        const { additionalData, filters, edits, duplicate, script } =
          stepProcess;

        const unstructuredData = additionalData as {
          // todo wip #672 refactor type
          skipEdit?: boolean;
          downloadFileName?: string[];
          downloadFileTypes?: string[];
          downloadTemplates?: string[];
          limitPageByCollectionRow?: [
            { downloadTemplate: string; collectionKey: string }
          ];
          type?: string;
          disableChanges?: [{ key: string; type: string }];
        };

        const nextStep = () => {
          resetModal();
          setStepIndex(stepIndex + 1);
        };

        if (script) {
          const runScript = () => {
            const doc = new Document({
              documentID: docId,
              number: docNumber,
              lastEdit: undefined,
              metaData: {
                [SYSTEM_FIELDS.formid]: docId,
                [SYSTEM_FIELDS.number]: docNumber,
              },
            });

            // default is call API atm
            // prepare params
            const scriptParams = {
              api,
              docData: utils.getFullData(doc, docData),
              injectDocData: (arg: {
                name: string;
                value: string | object;
                fillData?: object;
              }) => onInputChange?.(null, arg),
              isValidDocument: handleDocValidationScroll,
              store: {
                moduleStore: moduleStoreRef.current,
                pageStore: pageStoreRef.current,
                pageContentStore: pageContentStoreRef.current,
              },
              t,
            };

            // evaluate
            executeScript(script.data, scriptParams);
          };

          // get result from previous step or selected item
          const ids = getIdsByStepIndex(pageContentStoreRef.current, stepIndex);

          // init popup
          const modal = buildModalFromProcessInfos({ t });

          // show modal
          setModal({
            isOpen: true,
            options: getModalOptions({
              ...modal,
              message: ids?.length
                ? `${t('Apply script on')} ${ids.length} ${t('form(s)')}?`
                : t('Perform action?'),
              onSubmit: () => {
                runScript();

                // finish then move to next step
                nextStep();
              },
              onCancel: hideModal,
            }),
          });
        } else if (filters && filters.length > 0) {
          // has filters then build filters to get ids
          // sort data filters in store by order of filter input
          const sortedFilters = _.sortBy(filters, (f) => f.input.row);

          // update store and modalData with value filters
          const cloneStore = getCloneStoreUpdatedProcessData(
            pageContentStoreRef.current,
            stepIndex,
            {
              filters: sortedFilters,
            }
          );
          setPageContentStore(cloneStore);

          const allTemplateInputs = _.map(
            utils.getDistinctFieldsFromTemplates(templates),
            (f) => new Input(f)
          );

          // init popup with filters from this step
          const modal = buildModalFromProcessInfos({
            t,
            sortedFilterInputs: _.map(sortedFilters, (f) => new Input(f.input)),
            allTemplateInputs,
            inputOptions: utils.sortOptionsByText(
              allTemplateInputs.map(
                ({ key, name }) => new Option({ key, value: key, text: name })
              )
            ),
            disableChanges: unstructuredData?.disableChanges,
          });

          // show modal
          setModal({
            isOpen: true,
            options: getModalOptions({
              ...modal,
              onSubmit: async () => {
                // convert filter data to object key-value to perform invalid check
                const filters: ExecutionFilter[] = getProcessDataByStepIndex(
                  pageContentStoreRef.current,
                  stepIndex
                ).filters;

                const filterDataObject = _.reduce(
                  filters,
                  (obj, { key, value }) => ({ ...obj, [key]: value }),
                  {}
                );

                // get filterInputs from store
                const filterInputs = _.map(filters, (f) => f.input);

                // invalid check
                const invalidInputKey = isValidDocument(
                  t,
                  filterInputs,
                  filterDataObject
                );

                if (!invalidInputKey) {
                  // perform request
                  const result = await documentApi.getIDs(filters);

                  if (result?.data?.ids) {
                    const { ids, document } = result.data;

                    // update store with value result
                    const cloneStore = getCloneStoreUpdatedProcessData(
                      pageContentStoreRef.current,
                      stepIndex,
                      {
                        result: { ids, document },
                      }
                    );
                    setPageContentStore(cloneStore);

                    // notify
                    FwToast.success(`${ids.length} ${t('document(s) found')}`);

                    // finish then move to next step
                    setInvalidInputKey(undefined);
                    nextStep();
                  }
                } else {
                  setInvalidInputKey(invalidInputKey);
                }
              },
              onCancel: hideModal,
            }),
          });
        } else if (unstructuredData?.type === BUTTON_TYPE.download) {
          // get result from previous step or selected item
          const ids = getIdsByStepIndex(pageContentStoreRef.current, stepIndex);

          // init popup
          const modal = buildModalFromProcessInfos({ t, okBtnText: null });

          // show modal
          setModal({
            isOpen: true,
            options: getModalOptions({
              ...modal,
              message: `${ids?.length || ''} ${t('form(s)')}`,
              content: (
                <DownloadButtonContainer
                  name={t(`common|Download`)}
                  additionalData={{
                    downloadFileTypes: unstructuredData?.downloadFileTypes,
                    downloadTemplates: unstructuredData?.downloadTemplates,
                    limitPageByCollectionRow:
                      unstructuredData?.limitPageByCollectionRow,
                  }}
                  onClick={async ({ fileType, templateName }) => {
                    // download
                    const result = await documentApi.downloadManyAs(
                      ids,
                      templateName,
                      fileType,
                      unstructuredData?.downloadFileName,
                      utils.getPageLimitCollectionKeyByTemplate(
                        unstructuredData?.limitPageByCollectionRow,
                        templateName
                      )
                    );

                    if (result?.data?.fileUrl) {
                      // notify
                      FwToast.success(
                        `${t('...Downloading')} ${ids?.length || ''} ${t(
                          'form(s)'
                        )}`
                      );

                      // open to save file
                      utils.openInNewTab(result.data.fileUrl);
                    } else {
                      // notify
                      FwToast.error(`${t('Action could not be performed')}`);
                    }

                    // finish then move to next step
                    nextStep();
                  }}
                />
              ),
              onCancel: hideModal,
            }),
          });
        } else if (edits && edits.length > 0) {
          const skipEdit = unstructuredData?.skipEdit || false;

          // get result from previous step filter
          const filterIds: string[] = getIdsByStepIndex(
            pageContentStoreRef.current,
            stepIndex
          );

          // skip step edit if don't have any ids in filter result
          if (!skipEdit && !_.some(filterIds)) {
            nextStep();
            return;
          }

          // init popup with inputs from this step
          const modal = buildModalFromProcessInfos({
            t,
            sortedEditInputs: sortByStepRowColumnKey(
              _.map(edits, (f) => new Input(f.input))
            ),
            duplicate,
          });

          let editDataSource: object;

          if (stepIndex <= 0) {
            // get data source from selected object
            editDataSource =
              pageContentStoreRef.current.selections?.length === 1 && objectData
                ? utils.getFullRowData(objectData)
                : {};
          } else {
            // get result from previous step
            const { ids, document } =
              getProcessDataByPrevStep(pageContentStoreRef.current, stepIndex)
                ?.result || {};

            editDataSource =
              ids?.length === 1 && document
                ? utils.getFullRowData(document)
                : {};
          }

          // build edit data
          const newData = utils.getDataForPopupProcess(edits, editDataSource);

          // update store and modalData with value edits
          const cloneStore = getCloneStoreUpdatedProcessData(
            pageContentStoreRef.current,
            stepIndex,
            {
              edits: newData,
            }
          );
          setPageContentStore(cloneStore);

          // show modal
          setModal({
            isOpen: true,
            options: getModalOptions({
              ...modal,
              onSubmit: async () => {
                const invalidInputKey = isValidDocument(
                  t,
                  modal.inputs,
                  getProcessDataByStepIndex(
                    pageContentStoreRef.current,
                    stepIndex
                  ).edits
                );

                if (!invalidInputKey) {
                  if (!skipEdit) {
                    // perform request
                    const { edits, duplicate, step } = stepProcess;

                    const filters: Filter[] =
                      stepIndex <= 0
                        ? []
                        : getProcessDataByPrevStep(
                            pageContentStoreRef.current,
                            stepIndex
                          ).filters;

                    const batchProcess: BatchProcess = {
                      batchID: processID,
                      step,
                      documentIDs: filterIds.length > 50 ? [] : filterIds,
                      filters,
                      edits: utils.getProcessEditsByDocData(
                        edits,
                        getProcessDataByStepIndex(
                          pageContentStoreRef.current,
                          stepIndex
                        ).edits
                      ),
                    };

                    const response = await documentApi.process(batchProcess);

                    if (response.data.ids) {
                      // update store with value result
                      const cloneStore = getCloneStoreUpdatedProcessData(
                        pageContentStoreRef.current,
                        stepIndex,
                        {
                          result: { ids: response.data.ids },
                        }
                      );
                      setPageContentStore(cloneStore);

                      // notify
                      FwToast.success(
                        `${response.data.ids.length} ${t(
                          duplicate
                            ? 'document(s) duplicated'
                            : 'document(s) updated'
                        )}`
                      );
                    }

                    // finish then move to next step
                    setInvalidInputKey(undefined);
                  }

                  nextStep();
                } else {
                  setInvalidInputKey(invalidInputKey);
                }
              },
              onCancel: hideModal,
            }),
          });
        }
      } else if (stepIndex > 0) {
        console.log('Out of step!');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIndex, objectData, processID]);

  const resetModal = () => {
    setModal(defaultModal);
  };

  const hideModal = () => {
    resetModal();
    setStepIndex(undefined);
  };

  // parameters for modal
  const { isOpen, options } = modal;

  // get data from store
  const { filters, edits } = pageContentStore.processData?.[stepIndex] || {};
  const {
    filters: stepFilters,
    edits: stepEdits,
    rules,
  } = processSteps?.[stepIndex] || {};
  const data = _.some(stepFilters)
    ? filters
    : _.some(stepEdits)
    ? edits
    : undefined;

  const onChangeData = (
    _e: ChangeEvent<HTMLElement>,
    {
      fillData,
      ...htmlData
    }: /* todo wip #672 refactor type */
    { name: string; value: string; fillData?: object },
    indexFilter: number
  ) => {
    const isUpdateFilter = !_.isNil(indexFilter);
    const newDocData = fillData || utils.getDocDataFromNameValue(htmlData);

    // apply functions and more to update content store
    const localDispatch = (docState: Partial<DocState>) => {
      // clone then get process data of current step index
      const clonePCStore = _.cloneDeep(pageContentStoreRef.current);
      const processDataCurrentStep = clonePCStore.processData[stepIndex];

      if (isUpdateFilter) {
        if (fillData) {
          processDataCurrentStep.filters[indexFilter] = {
            ...processDataCurrentStep.filters[indexFilter],
            ...fillData,
          };
        } else {
          const { name, value } = htmlData;

          // update prop with new value
          processDataCurrentStep.filters[indexFilter][name] = value;
        }
      } else {
        // update edits in store
        processDataCurrentStep.edits = {
          ...processDataCurrentStep.edits,
          ...docState.docData,
        };
      }

      // update store
      setPageContentStore(clonePCStore);

      if (
        (isUpdateFilter &&
          processDataCurrentStep.filters[indexFilter].key ===
            invalidInputKey) ||
        htmlData.name === invalidInputKey
      ) {
        setInvalidInputKey(undefined);
      }
    };

    updateState(
      newDocData,
      { current: getEntityDoc(newDocData, options?.inputs, rules, []) },
      { current: data as { [key: string]: string } },
      { current: { steps: [], modalInputs: options?.inputs } },
      { current: undefined },
      localDispatch,
      {
        moduleStore: moduleStoreRef.current,
        pageStore: pageStoreRef.current,
        pageContentStore: pageContentStoreRef.current,
      }
    );
  };

  // sets the filter value undefined as long as the useEffect has not been executed
  if (prevProcessID != processID) {
    options && (options.filters = undefined);
  }

  return (
    <FwDrawer
      {...options}
      open={isDrawer && isOpen}
      data={data}
      placement={placement}
      onChangeData={onChangeData}
      invalidInputKey={invalidInputKey}
    >
      {children}
      {!isDrawer && isOpen && (
        <FwModal
          {...options}
          noDimmerClick
          open
          data={data}
          onChangeData={onChangeData}
          invalidInputKey={invalidInputKey}
        />
      )}
    </FwDrawer>
  );
};

FwProcess.propTypes = fwProcessPT;

export default FwProcess;
