import { TFunction } from 'i18next';
import _ from 'lodash';

import { Row } from 'core/model';

import { getMaskRowText } from '../FwMask.helpers';
import { MaskStructure } from '../FwMask.structures';

type Card = {
  color?: string;
  key?: string;
  row?: object;
  title?: string;
};

// todo wip#125 refactor
const formToCard = (
  maskStructure: MaskStructure,
  maskRow: Row & { data: Record<string, string>; color: string },
  t: TFunction
): Card => {
  const { key, data: rowData, color } = maskRow;
  const title = getMaskRowText(maskStructure, rowData, t);

  return {
    title,
    key,
    color,
    row: maskRow,
  };
};

const filterCards = (cards: Card[], searchText: string) =>
  cards && searchText
    ? _.filter(cards, (card) =>
        _.includes(card.title.toUpperCase(), searchText.toUpperCase())
      )
    : cards;

export { Card, filterCards, formToCard };
