import { Box, VStack } from '@chakra-ui/react';
import { bool, InferProps, node } from 'prop-types';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FwButton,
  FwLink,
  FwModal,
  useFwArea,
  useFwAuth,
  useFwTheme,
} from 'components/base';
import { FwModalProps } from 'core/model';
import utils from 'core/utils/utils';

import FwLogo from '../../common/logo/FwLogo';

const logoutModal = {
  name: 'Log out',
  open: true,
  submitName: 'Yes',
  cancelName: 'No',
};

export const mvmtCurve = 'cubic-bezier(.19,1,.22,1) 1.25s';

export const getSidebarWidth = (compact) => (compact ? '48px' : '150px');

const FwSidebar: FC<Props> = ({
  children,
  compact = false,
  open = true,
  ...props
}) => {
  const { t } = useTranslation();
  const { area } = useFwArea();
  const { logout } = useFwAuth();
  const { bg } = useFwTheme();

  const [modal, setModal] = useState<FwModalProps>();

  const showModal = () => {
    setModal(logoutModal);
  };

  const closeModal = () => {
    setModal(undefined);
  };

  // todo should store those values somewhere so they can be reused?
  const width = getSidebarWidth(compact);

  const sidebarStyle = {
    h: '100%',
    w: width,
    transition: `margin ${mvmtCurve}, width ${mvmtCurve}`,
    ml: open ? 0 : `-${width}`,
    bg,
    zIndex: 9,
    boxShadow: 'base',
    overflow: 'hidden auto',
    overscrollBehavior: 'contain',
    sx: {
      msOverflowStyle: 'none' /*in IE and Edge*/,
      scrollbarWidth: 'none' /*in Firefox*/,
      '&::-webkit-scrollbar': { display: 'none' /*in Chrome*/ },
      '@media print': { display: 'none' },
    },
  };

  return (
    <Box {...sidebarStyle} {...props}>
      <VStack spacing={4}>
        <FwLink basic to={utils.buildUrlNav(area)}>
          {/*todo wip#125 remove style prop*/}
          <FwLogo compact={compact} />
        </FwLink>
        <FwButton
          primary
          rounded
          small
          basic={compact ? undefined : true}
          leftIcon={compact ? 'RiLogoutBoxRLine' : undefined}
          tooltip={compact ? t('common|Log out') : undefined}
          onClick={showModal}
        >
          {!compact && t('common|Log out')}
        </FwButton>
        {modal && (
          <FwModal {...modal} onSubmit={logout} onCancel={closeModal} />
        )}
        {children}
      </VStack>
    </Box>
  );
};

const propTypes = {
  children: node,
  compact: bool,
  open: bool,
};

export type Props = InferProps<typeof propTypes>;

FwSidebar.propTypes = propTypes;

export default FwSidebar;
