import axios, { AxiosPromise, CancelToken, AxiosError } from 'axios';
import _ from 'lodash';

import Log from 'core/utils/log';
import { clearStorageAndRedirect, getToken } from 'core/utils/storage';

const getUrl = window.location;
const baseUrl = getUrl.protocol + '//' + getUrl.host + '/api/';

export type BaseApiParams = {
  cancelToken?: CancelToken;
  validErrors?: number[];
};

export const showApiError = async (error: Partial<AxiosError>) => {
  const details = (error?.response?.data as { details: string })?.details;

  if (details) {
    console.error(details);
  }
};

const validateStatusHelper = (status: number, validErrors?: number[]) =>
  (200 <= status && status < 300) ||
  (validErrors && validErrors.includes(status));

class BaseApi {
  external: boolean;
  instance = axios.create();

  constructor(external = false) {
    this.external = external;

    if (!this.external) {
      this.instance.defaults.baseURL = baseUrl;
    }

    this.instance.interceptors.request.use(
      (config) => {
        if (!this.external) {
          const token = getToken();

          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        } else {
          // todo investigate config for CORS
          //config.headers.common['Access-Control-Allow-Origin'] = '*';
          //config.headers.common['Access-Control-Allow-Methods'] =
          //  'GET,PUT,POST,DELETE,PATCH,OPTIONS';
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        showApiError(error);

        if (!this.external && error.response?.status === 401) {
          // log out unauthorized user
          clearStorageAndRedirect('');
        }

        return Promise.reject(error);
      }
    );
  }

  base =
    (func: string) =>
    <D>(url: string, params: BaseApiParams, data?: D) => {
      const extra = {
        ...params,
        validateStatus: (status: number) =>
          validateStatusHelper(status, params.validErrors),
      };

      return this.instance[func](..._.compact([url, data, extra])).catch(
        this.handleError
      );
    };

  get = <R>(url: string, params = {}): AxiosPromise<R> =>
    this.base('get')(url, params);

  post = <D, R>(url: string, data: D, params = {}): AxiosPromise<R> =>
    this.base('post')(url, params, data);

  put = <D, R>(url: string, data: D, params = {}): AxiosPromise<R> =>
    this.base('put')(url, params, data);

  delete = <R>(url: string, params = {}): AxiosPromise<R> =>
    this.base('delete')(url, params);

  handleError = (error: { message: string }) => {
    if (axios.isCancel(error)) {
      console.error('Request canceled', error.message);
      Log.error('Request canceled', error.message);
    }
  };
}

export const BaseExternalApi = new BaseApi(true);

export default new BaseApi(false);
