import ISO6391 from 'iso-639-1';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import languageApi from 'api/language/languageApi';
import { useApi } from 'api/useApi';
import { useFwSettings, FwSpinner } from 'components/base';
import { LOCALES } from 'core/utils/constant';

import Preferences from './Preferences';

const PreferencesContainer = () => {
  const { dispatchAppPreference } = useFwSettings();

  const [options, setOptions] = useState(undefined);

  const [getArgs] = useState([]);
  const { fetched, pending } = useApi(languageApi.getAll, getArgs);

  const getOptionByKey = (key: string) => ({
    key: key.toLowerCase(),
    value: key,
    text: ISO6391.getNativeName(key.toLowerCase()),
  });

  useEffect(() => {
    if (!pending && fetched) {
      const languageOptions = _.map(fetched?.languages, ({ key }) =>
        getOptionByKey(key)
      );

      // add default translation
      languageOptions.push(getOptionByKey(LOCALES['en']));

      // sorted alphabetically
      const sortLanguageOption = languageOptions.sort((a, b) =>
        a.key > b.key ? 1 : -1
      );

      setOptions(sortLanguageOption);
    }
  }, [fetched, pending]);

  const handleSetKey = useCallback(() => {
    // todo set in user instead or maybe refetch user from token
    // dispatchAppPreference({ appPreferenceID: key });
  }, []);

  // apply new preferences
  const handleEntityChange = useCallback(
    (data) => {
      // todo wip#585 value(s) will be overwritten by string values
      dispatchAppPreference(data);
    },
    [dispatchAppPreference]
  );

  const props = {
    options,
    handleSetKey,
    handleEntityChange,
  };

  return options ? <Preferences {...props} /> : <FwSpinner />;
};

export default PreferencesContainer;
